.filepond--credits {
    display: none;
}

.tab:is(.tab-active, [aria-selected="true"]):not(.tab-disabled):not([disabled]), .tab:is(input:checked) {
    border-color: #D576AA !important;
}

.tab:is(input:checked[type="radio"]):after {    
    color: #D576AA !important;
}

.custom-filepond .filepond--root {
    margin-bottom: 0 !important;
    min-height: 200px;
}
.custom-filepond .filepond--root .filepond--panel-root {
    background-color: transparent;
}

.custom-filepond .filepond--root .filepond--drop-label {
    min-height: 200px;
    height: auto !important;
}

.custom-filepond {
    .filepond--list {
        display: flex;
        
        li.filepond--item {
            width: 30%;
            height: 100px !important;

            &:not([data-filepond-item-state="load-invalid"]) {
                .filepond--panel-center.filepond--item-panel {
                    display: none;
                }
    
                .filepond--panel-bottom.filepond--item-panel {
                    display: none;
                }
            }
        }
    }
}

.arc-top-right {
    position: absolute;
    top: -24px;
    right: -40px;
    width: 50px;
    height: 50px;
    background-color: #312e81;
    border-bottom-left-radius: 50px;
}
.arc-bottom-left {
    position: absolute;
    bottom: -30px;
    left: -30px;
    width: 50px;
    height: 50px;
    background-color: #312e81;
    border-top-right-radius: 50px;
}

.select2-remove-chevron {
    .flex.flex-none {
      .h-full {
        .w-px {
          display: none !important;
        }
      }
      div {
        svg.transition {
          display: none !important;
        }
      }
    }
  }
.questionniare-grid-form {    
    .tabulator-headers {
        .tabulator-col {
            background: #F9FAFB !important;
        }
    }
    .tabulator-row {
        &.tabulator-row-even {
           background-color: #fff !important;
        }
        .tabulator-cell.tabulator-frozen {
            background-color: #F9FAFB !important;
        }
    }
   
}